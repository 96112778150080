import OnboardingLayout from 'layouts/onboarding/OnboardingLayout';
import { MainLayout, ProtectedPages } from 'layouts/organization';
import { UserLayout } from 'layouts/user';
import CalendarPage from 'pages/Calendar/CalendarPage';
import CollectionPage from 'pages/Collection/CollectionPage';
import CollectionsPage from 'pages/Collection/CollectionsPage';
import HomePage from 'pages/Home/HomePage';
import LandingPage from 'pages/Landing/LandingPage';
import LoginPage from 'pages/Login/LoginPage';
import LoginPageRedirect from 'pages/Login/LoginPageRedirect';
import OnboardingCalendarPage from 'pages/Onboarding/OnboardingCalendarPage';
import OrganizationRedirectionPage from 'pages/Organization/OrganizationRedirectionPage';
import PrivateVideosPage from 'pages/Private/PrivateVideosPage';
import SettingsPage from 'pages/Settings/SettingsPage';
import IntegrationsTab from 'pages/Settings/tabs/IntegrationsTab';
import MembersTab from 'pages/Settings/tabs/OrganizationTab';
import OrganizationTab from 'pages/Settings/tabs/OrganizationTab';
import PlanTab from 'pages/Settings/tabs/PlanTab';
import TagVideosPage from 'pages/Tag/TagVideosPage';
import SalesforceIntegrationPage from 'pages/User/SalesforceIntegrationPage';
import UserPage from 'pages/User/UserPage';
import VideoPage from 'pages/Video/VideoPage';
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import OnboardingPage from './Onboarding/OnboardingPage';
import OnboardingRecordPage from './Onboarding/OnboardingRecordPage';
import OrganizationCreationPage from './Organization/OrganizationCreationPage';
import SummaryTab from './Settings/tabs/SummaryTab';

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="login/microsoft" element={<LoginPage />} />
        <Route path="login/redirect" element={<LoginPageRedirect />} />
        <Route path="onboarding" element={<OnboardingLayout />}>
          <Route index element={<OnboardingPage />} />
          <Route path="calendar" element={<OnboardingCalendarPage />} />
          <Route path="record" element={<OnboardingRecordPage />} />
        </Route>
        <Route path="user" element={<UserLayout />}>
          <Route index element={<UserPage />} />
          <Route path="organizations/create" element={<OrganizationCreationPage />} />
          <Route path="integrations/salesforce" element={<SalesforceIntegrationPage />} />
        </Route>
        <Route path="o" element={<OrganizationRedirectionPage />} />
        <Route path="o/:organizationUuid" element={<MainLayout />}>
          <Route path="v/:videoUuid" element={<VideoPage />} />
          <Route path="t/:tagUuid" element={<TagVideosPage />} />
          <Route element={<ProtectedPages />}>
            <Route path="v" element={<HomePage />} />
            <Route path="c" element={<CollectionsPage />} />
            <Route path="c/:collectionUuid" element={<CollectionPage />} />
            <Route path="settings" element={<SettingsPage />}>
              <Route index element={<OrganizationTab />} />
              <Route path="integrations" element={<IntegrationsTab />} />
              <Route path="members" element={<MembersTab />} />
              <Route path="plan" element={<PlanTab />} />
              <Route path="summary" element={<SummaryTab />} />
            </Route>
            <Route path="private" element={<PrivateVideosPage />} />
            <Route path="calendar" element={<CalendarPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default Pages;
