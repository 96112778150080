import { Link, PeopleAlt } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Button,
  Card,
  CardActions,
  Divider,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import MentionCard from 'components/mention/MentionCard';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import MentionCardUser from 'components/mention/MentionCardUser';
import React from 'react';
import { useUpdateSharingMutation } from 'redux/services/video';
import { Permission, Video } from 'redux/services/video.types';

type VideoSettingsButtonProps = {
  video: Video;
};

const VideoSettingsButton = ({ video }: VideoSettingsButtonProps) => {
  // TODO: If i'm not the owner, I can't change the sharing options

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // Menu close
  const [updateSharingMutation] = useUpdateSharingMutation();
  const handleMenuClose = () => {
    updateSharingMutation({
      uuid: video.uuid,
      sharing: { linkPermission: linkSharing, organizationPermission: organizationSharing },
    });
    setAnchorEl(null);
  };

  // Organization Sharing
  const [organizationSharing, setOrganizationSharing] = React.useState<Permission>(video.organizationPermission);
  const handleOrganizationSharingChange = (event: SelectChangeEvent) => {
    setOrganizationSharing(event.target.value as Permission);
  };

  // Link Sharing
  const [linkSharing, setLinkSharing] = React.useState<Permission>(video.linkPermission);
  const handleLinkSharingChange = (event: SelectChangeEvent) => {
    setLinkSharing(event.target.value as Permission);
  };

  // Link to Clipboard
  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href);
    handleMenuClose();
  };

  if (!video) return null;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-label="Share video"
        startIcon={<PeopleAlt />}
        onClick={handleMenuIconClick}
      >
        Share
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="video-menu"
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Card sx={{ border: 'none' }}>
          <Typography sx={{ p: 2 }} variant="subtitle1">
            Share Video
          </Typography>
          <ListItem>
            <ListItemText>
              <MentionCardUser user={video.user} />
            </ListItemText>
            <Button disabled variant="outlined" color="info" sx={{ mx: 2 }}>
              Owner
            </Button>
          </ListItem>
          <ListItem>
            <ListItemText>
              <MentionCardOrganization secondary="Organization" organization={video.organization} />
            </ListItemText>
            <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
              <Select value={organizationSharing} onChange={handleOrganizationSharingChange} size="small">
                <MenuItem value={Permission.COMMENT}>Can comment</MenuItem>
                <MenuItem value={Permission.NONE}>No access</MenuItem>
              </Select>
            </Box>
          </ListItem>
          <ListItem>
            <ListItemText>
              <MentionCard
                avatar={<LanguageIcon />}
                avatarBackgroundColor="transparent"
                primary="Anyone with the link"
                secondary="Anyone on the internet with the link"
              />
            </ListItemText>
            <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
              <Select value={linkSharing} onChange={handleLinkSharingChange} size="small">
                <MenuItem value={Permission.VIEW}>Can view</MenuItem>
                <MenuItem value={Permission.NONE}>No access</MenuItem>
              </Select>
            </Box>
          </ListItem>
          <Divider />
          <CardActions>
            <Button variant="outlined" color="info" startIcon={<Link />} onClick={copyLinkToClipboard}>
              Copy Link
            </Button>
          </CardActions>
        </Card>
      </Menu>
    </>
  );
};

export default VideoSettingsButton;
