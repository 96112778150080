import { Business, CreditCard, Extension, Notes } from '@mui/icons-material';
import { Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

function SettingsPage() {
  const navigate = useNavigate();
  function handleChange(event: React.ChangeEvent<{}>, newValue: string): void {
    setValue(newValue);
    navigate(newValue);
  }
  const [value, setValue] = React.useState('');

  return (
    <>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Typography gutterBottom variant="h1">
          Settings
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Settings tabs"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab icon={<Business />} iconPosition="start" label="Organization & Members" value="" />
          <Tab icon={<Extension />} iconPosition="start" label="Integrations" value="integrations" />
          <Tab icon={<Notes />} iconPosition="start" label="AI Summary" value="summary" />
          <Tab icon={<CreditCard />} iconPosition="start" label="Plan" value="plan" />
        </Tabs>
      </ContainerFluid>
      <Outlet />
    </>
  );
}

export default SettingsPage;
