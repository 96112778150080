import SettingsIcon from '@mui/icons-material/Settings';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Menu,
  Switch,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { UserSetting } from 'redux/services/api.types';
import {
  useGetCurrentUserQuery,
  useGetCurrentUserSettingQuery,
  useUpdateCurrentUserSettingAutoRecordMutation,
} from 'redux/services/user';

const RecordingSettingsButton = () => {
  const { data: settings, isLoading: userSettingIsLoading } = useGetCurrentUserSettingQuery();
  const { data: user } = useGetCurrentUserQuery();
  const [updateRecordingSettingsMutation, updateRecordingsSettingsResult] =
    useUpdateCurrentUserSettingAutoRecordMutation();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // Menu close
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    initForm(settings);
  }, [settings]);

  // Form Initial
  const [recordChecked, setRecordChecked] = useState<boolean>(false);
  const initForm = (settings: UserSetting | undefined) => {
    setRecordChecked(
      !!settings && settings.autoRecordExternal && settings.autoRecordInternal && settings.autoRecordOrganizerOnly,
    );
  };
  useEffect(() => {
    initForm(settings);
  }, [settings, handleMenuClose]);
  // Form submission
  const handleRecordChange = (switchEvent: any) => {
    setRecordChecked(switchEvent.target.checked);
  };
  const handleSaveRecordSettings = () => {
    updateRecordingSettingsMutation({
      autoRecordExternal: recordChecked,
      autoRecordInternal: recordChecked,
      autoRecordOrganizerOnly: recordChecked,
    });
  };
  useEffect(() => {
    if (updateRecordingsSettingsResult.isSuccess) {
      handleMenuClose();
    }
  }, [handleMenuClose, updateRecordingsSettingsResult]);

  if (!settings) return null;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-label="Recording Settings"
        startIcon={<SettingsIcon />}
        onClick={handleMenuIconClick}
      >
        Recording Settings
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="recording-menu"
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
      >
        <Card>
          <Typography sx={{ p: 2 }} variant="subtitle1">
            Recording Settings
          </Typography>
          <Divider />

          <CardContent sx={{ p: 2 }}>
            <Typography variant="subtitle2">Choose which meetings to record.</Typography>
            {user && <Typography variant="body2">Calendar connected: {user.email}</Typography>}
            <FormControlLabel
              sx={{ mt: 2 }}
              control={<Switch />}
              label="Record all meetings that I own"
              checked={recordChecked}
              onChange={handleRecordChange}
            />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="info" onClick={handleMenuClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={updateRecordingsSettingsResult.isLoading || userSettingIsLoading}
              variant="contained"
              color="primary"
              onClick={handleSaveRecordSettings}
            >
              Save
            </LoadingButton>
          </CardActions>
        </Card>
      </Menu>
    </>
  );
};

export default RecordingSettingsButton;
