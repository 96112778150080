import ClearIcon from '@mui/icons-material/Clear';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, ButtonGroup } from '@mui/material';
import { getFieldComponent } from 'components/salesforce/SalesforcePanel/SalesforceRecordField.utils';
import { useField, useFormikContext } from 'formik';
import React from 'react';

interface Props {
  field: any; // TODO: define the type
  suggestion?: string | boolean;
}

const SalesforceRecordField = ({ field, suggestion }: Props) => {
  // Value
  const { values } = useFormikContext<{ [fieldName: string]: any }>();
  const [input, meta, helpers] = useField(field.name);
  const label = meta.value === meta.initialValue ? field.label : field.label + ' *';

  //Component
  const FieldComponent = getFieldComponent(field);

  // AutoComplete
  const [reviewed, setReviewed] = React.useState(false);
  const handleAcceptSuggestion = async () => {
    await helpers.setValue(suggestion);
    setReviewed(true);
  };
  const handleRefuseSuggestion = async () => {
    setReviewed(true);
  };

  if (!FieldComponent) return null;

  return (
    <>
      <FieldComponent
        field={field}
        name={field.name}
        label={label}
        value={values[field.name]}
        disabled={!field.updateable}
        error={!!meta.error}
        helperText={meta.error}
        InputProps={input}
      />
      {!reviewed && suggestion && (
        <ButtonGroup>
          <Button
            startIcon={<TaskAltIcon fontSize="small" color="primary" />}
            variant="outlined"
            size="small"
            color="info"
            sx={{ textAlign: 'left' }}
            onClick={handleAcceptSuggestion}
          >
            {suggestion}
          </Button>

          <Button size="small" color="info" onClick={handleRefuseSuggestion}>
            <ClearIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default React.forwardRef(SalesforceRecordField);
