import { Box, ButtonGroup, Card, CardActions, CardContent, Chip } from '@mui/material';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/App';
import { CreateCheckoutSessionBody, useCreateCheckoutSessionMutation } from 'redux/services/stripe';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';
import { useGetOrganizationQuery } from 'redux/services/organization';

declare var Rewardful: { referral: string };

const PlanTabStyled = styled('div')`
  .limited-time-offer {
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .pro-cover {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .plan-name {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .plan-description {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: rgb(64, 64, 64);
    font-weight: 300;
  }

  .previous-price {
    color: gray;
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-decoration-line: line-through;
  }

  .price {
    padding: 20px 15px;
    font-size: 72px;
    line-height: 58px;
    font-weight: 800;
  }

  ul {
    padding-left: 30px;
  }

  ul li {
    padding-left: 5px;
  }

  ul li::marker {
    content: '✓';
    color: green;
  }
`;

function PlanTab() {
  // Current Organization
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const {
    data: currentOrganization,
    isLoading: currentOrganizationLoading,
    isSuccess: currentOrganizationSuccess,
  } = useGetOrganizationQuery(currentOrganizationUuid);

  // Pricing
  const [currency, setCurrency] = useState<'$' | '€'>('$');
  const [frenquency, setFrequency] = useState<'monthly' | 'annually'>('monthly');

  // Payment
  const [createCheckoutSession, createCheckoutSessionResponse] = useCreateCheckoutSessionMutation();
  const startPayment = async () => {
    const checkoutBody: CreateCheckoutSessionBody = {};
    if (Rewardful.referral) checkoutBody.referralId = Rewardful.referral;
    await createCheckoutSession(checkoutBody);
  };
  useEffect(() => {
    if (createCheckoutSessionResponse.data) {
      window.location.href = createCheckoutSessionResponse.data.url;
    }
  }, [createCheckoutSessionResponse]);

  return (
    <PlanTabStyled>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Box pt={2} pl={2} pb={0} display="flex" flexDirection="row" gap={2} alignItems="center">
          <ButtonGroup>
            <Button color={currency === '$' ? 'primary' : 'info'} onClick={() => setCurrency('$')}>
              $
            </Button>
            <Button color={currency === '€' ? 'primary' : 'info'} onClick={() => setCurrency('€')}>
              €
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button color={frenquency === 'monthly' ? 'primary' : 'info'} onClick={() => setFrequency('monthly')}>
              Monthly
            </Button>
            <Button color={frenquency === 'annually' ? 'primary' : 'info'} onClick={() => setFrequency('annually')}>
              Annually
            </Button>
          </ButtonGroup>
          <Chip label="Limited time offer" size="small" color="secondary" className="limited-time-offer" />
        </Box>
      </ContainerFluid>
      <ContainerFluid>
        <Box display="flex" flexDirection="row" p={2} gap={3} flexWrap="wrap">
          <Card sx={{ width: '330px' }}>
            {/* Description */}
            <CardContent className="pro-cover">
              <Box textAlign="center" pt={1}>
                <Chip
                  label={frenquency === 'monthly' ? '20% SAVINGS' : '40% SAVINGS'}
                  size="small"
                  color="secondary"
                  className="limited-time-offer"
                />
              </Box>
              <div className="plan-name">Pro</div>
              <div className="plan-description">Best option for small teams who want to automate meeting notes.</div>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="body2" component="div" className="previous-price">
                  {currency === '€' && frenquency === 'monthly' ? '49€' : ''}
                  {currency === '$' && frenquency === 'monthly' ? '$49' : ''}
                  {currency === '€' && frenquency === 'annually' ? '39€' : ''}
                  {currency === '$' && frenquency === 'annually' ? '$39' : ''}
                </Typography>
                <Typography variant="h4" component="div" className="price">
                  {currency === '€' && frenquency === 'monthly' ? '39€' : ''}
                  {currency === '$' && frenquency === 'monthly' ? '$39' : ''}
                  {currency === '€' && frenquency === 'annually' ? '29€' : ''}
                  {currency === '$' && frenquency === 'annually' ? '$29' : ''}
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" component="div">
                    / User
                  </Typography>
                  <Typography variant="body1" component="div">
                    / Month
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            {/* CTA */}
            {currentOrganizationLoading && (
              <CardContent sx={{ textAlign: 'center' }}>
                <Chip label="Loading..." />
              </CardContent>
            )}
            {currentOrganizationSuccess && currentOrganization.subscriptions.length === 0 && (
              <CardActions>
                <Button fullWidth color="primary" variant="contained" onClick={startPayment}>
                  Upgrade
                </Button>
              </CardActions>
            )}
            {currentOrganizationSuccess && currentOrganization.subscriptions.length > 0 && (
              <CardContent sx={{ textAlign: 'center' }}>
                <Chip color="primary" label="Your current plan 🎉" />
              </CardContent>
            )}
            {/* Features */}
            <CardContent>
              <Typography variant="body1">
                <ul>
                  <li>Goelo Bot Notetaker</li>
                  <li>
                    AI Summary <b>GPT4</b>
                  </li>
                  <li>AI Summary custom</li>
                  <li>AI Second Brain</li>
                  <li>Collaborative Workspace</li>
                  <li>
                    1 hour setup consulting for free
                    <br />
                    (value: $200)
                  </li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ width: '330px' }}>
            {/* Description */}
            <CardContent className="pro-cover">
              <Box textAlign="center" pt={1}>
                <Chip
                  label={frenquency === 'monthly' ? '34% SAVINGS' : '45% SAVINGS'}
                  size="small"
                  color="secondary"
                  className="limited-time-offer"
                />
              </Box>
              <div className="plan-name">Business</div>
              <div className="plan-description">To unlock the full power of AI and Salesforce synchronization.</div>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="body2" component="div" className="previous-price">
                  {currency === '€' && frenquency === 'monthly' ? '89€' : ''}
                  {currency === '$' && frenquency === 'monthly' ? '$89' : ''}
                  {currency === '€' && frenquency === 'annually' ? '79€' : ''}
                  {currency === '$' && frenquency === 'annually' ? '$79' : ''}
                </Typography>
                <Typography variant="h4" component="div" className="price">
                  {currency === '€' && frenquency === 'monthly' ? '59€' : ''}
                  {currency === '$' && frenquency === 'monthly' ? '$59' : ''}
                  {currency === '€' && frenquency === 'annually' ? '49€' : ''}
                  {currency === '$' && frenquency === 'annually' ? '$49' : ''}
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" component="div">
                    / User
                  </Typography>
                  <Typography variant="body1" component="div">
                    / Month
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            {/* CTA */}
            {currentOrganizationLoading && (
              <CardContent sx={{ textAlign: 'center' }}>
                <Chip label="Loading..." />
              </CardContent>
            )}
            <CardActions>
              <Button fullWidth color="primary" variant="contained" onClick={startPayment}>
                Upgrade
              </Button>
            </CardActions>
            {/* Features */}
            <CardContent>
              <Typography variant="body1">
                <p>Everything in Goelo Pro, plus:</p>
                <ul>
                  <li>Salesforce Integration</li>
                  <li>
                    Salesforce Autofill{' '}
                    <Chip label="New" size="small" color="secondary" className="limited-time-offer" />
                  </li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </ContainerFluid>
    </PlanTabStyled>
  );
}

export default PlanTab;
