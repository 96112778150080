import { Forward10, Fullscreen, Pause, PlayArrow, Replay, Replay10, VolumeOff, VolumeUp } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, createSvgIcon, IconButton, SvgIcon } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Slider from '@mui/material/Slider';
import classNames from 'classnames';
import useFullscreen from 'hooks/resizing/useFullscreen';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { playerActions } from 'redux/Player';
import { getLastSeek } from 'redux/Player/player.selectors';
import { useCreateVideoCommentMutation } from 'redux/services/video-comment';
import { Video } from 'redux/services/video.types';
import { timeUtils } from 'services/time/timeUtils';
import StyledVideoPlayer, { BufferLoader, SeekSlider } from './VideoPlayer.style';

const ClipIcon = createSvgIcon(
  <>
    <path d="M14.594 4.495l-.585-1.91L15.922 2l.585 1.91-1.913.585zM11.14 3.46l.585 1.911 1.913-.584-.585-1.91-1.913.583zM8.856 6.247l-.584-1.91 1.912-.584.585 1.91-1.913.584zM5.403 5.213l.584 1.91L7.9 6.54l-.585-1.911-1.912.584zM2.534 6.09L3.118 8l1.913-.584-.585-1.91-1.912.583zM5 9H3v7a2 2 0 002 2h10a2 2 0 002-2V9h-2v7H5V9z" />
    <path d="M8 9H6v2h2V9zM9 9h2v2H9V9zM14 9h-2v2h2V9z" />
  </>,
  'Clip',
);

const playbackRates: number[] = [1, 1.2, 1.5, 1.7, 2];

const VideoPlayer = ({ video }: { video: Video }) => {
  const dispatch = useDispatch();

  // Ref
  const player = useRef<ReactPlayer>(null);
  const playerWrapper = useRef<HTMLDivElement | null>(null);
  const [isFullscreen, setFullscreen] = useFullscreen(playerWrapper);

  // States
  const [playing, setPlaying] = useState<boolean>(false);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const progress = useRef(0);
  const [duration, setDuration] = useState<number>(0);
  const [playbackRateIdx, setPlaybackRateIdx] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [savedVolume, setSavedVolume] = useState<number>(1);
  const [buffering, setBuffering] = useState<boolean>(false);

  // Request
  const [createComment, createCommentResult] = useCreateVideoCommentMutation();

  // Redux
  const lastSeek = useSelector(getLastSeek);

  useEffect(() => {
    player.current && player.current.seekTo(lastSeek.seek);
  }, [lastSeek]);

  // Component
  const initPlayerWrapper = React.useCallback((node: HTMLDivElement | null) => {
    if (node) {
      playerWrapper.current = node;
    }
  }, []);

  // Controls
  const handlePlayPause = useCallback(() => {
    if (played < duration) {
      setPlaying(!playing);
    } else {
      player.current && player.current.seekTo(0);
      setPlaying(true);
    }
  }, [playing, setPlaying, player, played, duration]);

  const handleSeekChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSeeking(true);
      setPlayed(newValue);
    }
  };

  const handleSeekChangeCommitted = useCallback(
    // @ts-ignore
    (e, newValue) => {
      player.current && player.current.seekTo(newValue);
      setPlayed(newValue);
      setSeeking(false);
    },
    [player],
  );

  const handleBackward10Click = useCallback(() => {
    if (duration !== 0 && player.current) {
      player.current.seekTo(Math.max(played - 10, 0), 'seconds');
      setPlaying(true);
    }
  }, [player, duration, played]);

  const handleForward10Click = useCallback(() => {
    if (duration !== 0 && player.current) {
      player.current.seekTo(Math.min(played + 10, duration - 0.01), 'seconds');
      setPlaying(true);
    }
  }, [player, played, duration]);

  const handleMuteToggle = useCallback(() => {
    if (volume < 0.05) setVolume(savedVolume);
    else setVolume(0);
  }, [volume, savedVolume]);

  const handleVolumeChange = useCallback(
    // @ts-ignore
    (e, value) => {
      setVolume(value);
      setSavedVolume(value);
    },
    [],
  );

  const handleFullScreenToggle = useCallback(() => setFullscreen(!isFullscreen), [setFullscreen, isFullscreen]);

  const handlePlaybackRateChange = useCallback(() => {
    setPlaybackRateIdx((currentPlaybackRateIdx) => (currentPlaybackRateIdx + 1) % playbackRates.length);
  }, [setPlaybackRateIdx]);

  const handleProgress = useCallback(
    ({ playedSeconds }: { playedSeconds: number }) => {
      progress.current = playedSeconds;
      if (!seeking) {
        setPlayed(playedSeconds);
        dispatch(playerActions.setProgress(playedSeconds));
      }
    },
    [seeking, dispatch],
  );

  const handleDuration = useCallback((duration: number) => setDuration(duration), [setDuration]);
  const handleBuffer = useCallback(() => setBuffering(true), [setBuffering]);
  const handleBufferEnd = useCallback(() => setBuffering(false), [setBuffering]);

  // Share with time
  const [urlSearchParams] = useSearchParams();
  const shareWithTime = urlSearchParams.get('t');
  useEffect(() => {
    if (!shareWithTime || duration === 0) return;

    const time = parseInt(shareWithTime);
    if (time > duration) return;

    player.current && player.current.seekTo(time);
  }, [shareWithTime, duration]);

  /**
   * #######################################################
   * #                     Clips                           #
   * #######################################################
   */
  const [clipping, setClipping] = useState<boolean>(false);
  const [clipValues, setClipValues] = React.useState<number[]>([2, 10, 15]);
  const handleClipStart = () => {
    setClipValues([
      Math.max(played - (duration / 100) * 5, 0),
      played,
      Math.min(played + (duration / 100) * 5, duration),
    ]);
    setClipping(true);
  };
  const handleClipValidation = () => {
    createComment({
      videoUuid: video.uuid,
      offset: clipValues[0],
      offsetEnd: clipValues[2],
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: ' Clip',
              },
            ],
          },
        ],
      },
    });
  };
  const handleClipCancel = () => {
    setClipping(false);
  };
  const minDistance = 1;
  const handleClipValuesChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setClipValues([Math.min(newValue[0], clipValues[2] - minDistance), clipValues[1], clipValues[2]]);
    }
    if (activeThumb === 1) {
      setClipValues([clipValues[0], newValue[1], clipValues[2]]);
      setSeeking(true);
      setPlayed(newValue[1]);
    }
    if (activeThumb === 2) {
      setClipValues([clipValues[0], clipValues[1], Math.max(newValue[2], clipValues[0] + minDistance)]);
    }
  };

  const handleClipValuesChangeCommitted = useCallback(
    // @ts-ignore
    (e, newValue) => {
      player.current && player.current.seekTo(newValue[1]);
      setPlayed(newValue[1]);
      setSeeking(false);
    },
    [player],
  );

  useEffect(() => {
    if (!clipping) return;
    if (played === clipValues[1]) return;
    setClipValues([clipValues[0], played, clipValues[2]]);
  }, [played, clipValues, clipping]);

  useEffect(() => {
    if (!clipping) return;
    if (played >= clipValues[2]) {
      setPlaying(false);
    }
  }, [clipping, played, clipValues]);

  useEffect(() => {
    if (createCommentResult.isSuccess) {
      setClipping(false);
    }
  }, [createCommentResult]);

  const valueLabelFormat = (value: number) => {
    return timeUtils.secondsToTime(value);
  };

  return (
    <StyledVideoPlayer isFullscreen={isFullscreen} ref={initPlayerWrapper}>
      <div className="video-player-aspect-ratio-outside">
        <div className="video-player-aspect-ratio-inside" onClick={handlePlayPause}>
          <ReactPlayer
            ref={player}
            url={video.url}
            playing={playing}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
            playbackRate={playbackRates[playbackRateIdx]}
            volume={volume}
            height="100%"
            width="100%"
          />
          {buffering && <BufferLoader />}
        </div>
      </div>
      <div className="video-player-progress-bar">
        <div className="progress-bar">
          {!clipping && (
            <SeekSlider
              size="small"
              min={0}
              max={duration}
              step={0.001}
              value={played}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              onChange={handleSeekChange}
              onChangeCommitted={handleSeekChangeCommitted}
              className="control"
            />
          )}
          {clipping && (
            <SeekSlider
              size="small"
              min={0}
              max={duration}
              step={0.001}
              value={clipValues}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              onChange={handleClipValuesChange}
              onChangeCommitted={handleClipValuesChangeCommitted}
              className="control"
              disableSwap
            />
          )}
        </div>
      </div>
      <div className="video-player-controls">
        <div className="controls-group">
          <IconButton onClick={handlePlayPause} className="control">
            {playing && played < duration ? <Pause /> : played < duration ? <PlayArrow /> : <Replay />}
          </IconButton>
          <IconButton onClick={handleBackward10Click} className="control" disabled={played === 0}>
            <Replay10 />
          </IconButton>
          <IconButton onClick={handleForward10Click} className="control" disabled={played === duration}>
            <Forward10 />
          </IconButton>
          <Button onClick={handlePlaybackRateChange} className="control rate" size="small">
            {playbackRates[playbackRateIdx]}x
          </Button>
          <Box display="flex" flexDirection="row" className={classNames('control', 'volume-controls')}>
            <IconButton onClick={handleMuteToggle}>{volume === 0 ? <VolumeOff /> : <VolumeUp />}</IconButton>
            <span className={classNames('volume-slider-wrap')}>
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                orientation="horizontal"
                size="small"
              />
            </span>
          </Box>
        </div>
        <div className="controls-group spacer"></div>
        <div className="controls-group right">
          <span className="duration control">
            {timeUtils.secondsToTime(played)} / {timeUtils.secondsToTime(duration)}
          </span>
          {!clipping && (
            <IconButton onClick={handleClipStart} className="control">
              <SvgIcon component={ClipIcon}></SvgIcon>
            </IconButton>
          )}
          {clipping && (
            <>
              {!createCommentResult.isLoading && (
                <Button onClick={handleClipCancel} className="control" variant="outlined" color="info">
                  Cancel
                </Button>
              )}
              <LoadingButton
                loading={createCommentResult.isLoading}
                onClick={handleClipValidation}
                className="control"
                variant="outlined"
              >
                Clip
              </LoadingButton>
            </>
          )}
          <IconButton onClick={handleFullScreenToggle} className="control">
            <Fullscreen />
          </IconButton>
        </div>
      </div>
    </StyledVideoPlayer>
  );
};

export default VideoPlayer;
