import { Box, Button, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, appSelectors } from 'redux/App';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useGetVideosQuery } from 'redux/services/video';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';
import Fuse from 'fuse.js';
import OrganizerFilter from '../../components/video/Filters/OrganizerFilter/OrganizerFilter';
import PeriodFilter from '../../components/video/Filters/PeriodFilter/PeriodFilter';
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import dayjs from 'dayjs';
import { Period, VideosView } from '../../components/video/Filters/filter.types';
import VideosTableView from '../../components/video/VideosTableView/VideosTableView';
import VideosGalleryView from '../../components/video/VideosGalleryView/VideosGalleryView';
import VideoActions from '../../components/video/Filters/VideoActions/VideoActions';
import Divider from '@mui/material/Divider';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import VideosGraph from '../../components/video/VideosGraph/VideosGraph';

function HomePage() {
  const dispatch = useDispatch();

  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const { data: videos } = useGetVideosQuery({ sharing: 'ORGANIZATION' });

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const [filteredVideosExcludingDate, setFilteredVideosExcludingDate] = useState(videos);

  const [organizerFilter, setOrganizerFilter] = useState<string[]>([]);
  const [periodFilter, setPeriodFilter] = useState<Period>({
    startDate: null,
    endDate: null,
    shortcut: null,
  });

  const view = useSelector(appSelectors.getVideoView);
  const handleViewChange = (event: React.SyntheticEvent, newValue: VideosView) => {
    if (newValue !== null) dispatch(appActions.videoViewChanged(newValue));
  };

  useEffect(() => {
    if (!videos) return;
    // Organizer filter ---------------------
    const videosFilteredByOrganizer = videos.filter((video) => {
      if (organizerFilter.length === 0) return true;

      return organizerFilter.includes(video.user.uuid);
    });

    // Search filter ---------------------
    const fuse = new Fuse(videosFilteredByOrganizer, {
      keys: ['title', 'user.name'],
      includeScore: true,
    });

    const videosFilteredBySearch = searchTerm
      ? fuse.search(searchTerm).map(({ item }) => item)
      : videosFilteredByOrganizer;

    setFilteredVideosExcludingDate(videosFilteredBySearch);

    // Date filter ---------------------
    const videosFilteredByDate = videosFilteredBySearch.filter((video) => {
      if (periodFilter.startDate && dayjs(video.createdAt).isBefore(periodFilter.startDate.startOf('day')))
        return false;
      if (periodFilter.endDate && dayjs(video.createdAt).isAfter(periodFilter.endDate.endOf('day'))) return false;

      return true;
    });

    setFilteredVideos(videosFilteredByDate);
  }, [organizerFilter, periodFilter, searchTerm, videos]);

  const clearFilters = () => {
    setOrganizerFilter([]);
    setPeriodFilter({ startDate: null, endDate: null, shortcut: null });
    setSearchTerm('');
  };

  const handleOrganizerFilter = (organizers: string[]) => {
    setOrganizerFilter(organizers);
  };

  const handlePeriodFilter = (period: Period) => {
    setPeriodFilter(period);
  };

  // Selection
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const handleSelectionChange = (newRowSelectionModel: React.SetStateAction<GridRowSelectionModel>) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  return (
    <div>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Typography gutterBottom variant="h1">
          {currentOrganization ? `${currentOrganization.name}'s Workspace` : 'Workspace'}
        </Typography>
      </ContainerFluid>
      {currentOrganization && currentOrganization.subscriptions.length > 0 && (
        <>
          <ContainerFluid>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="left"
              gap="10px"
              flexWrap="wrap"
            >
              <ToggleButtonGroup
                value={view}
                exclusive
                size="small"
                onChange={handleViewChange}
                sx={{ height: '30px' }}
              >
                <ToggleButton value={'gallery'}>
                  <GridViewIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value={'table'}>
                  <ViewListOutlinedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Divider orientation="vertical" flexItem />
              <VideoActions videoUuids={rowSelectionModel} />
              <Divider orientation="vertical" flexItem />
              <OrganizerFilter
                key={JSON.stringify(organizerFilter)}
                initialValues={organizerFilter}
                onSubmit={handleOrganizerFilter}
              />
              <PeriodFilter
                key={JSON.stringify(periodFilter)}
                initialPeriod={periodFilter}
                onSubmit={handlePeriodFilter}
              />
              <TextField
                id="input-with-icon-textfield"
                variant="outlined"
                size="small"
                placeholder="Meeting Title, Organizer Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  sx: {
                    paddingLeft: '10px',
                    height: '30px',
                  },
                }}
                sx={{
                  width: '270px',
                }}
                value={searchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(event.target.value);
                }}
              />
              <Button size="small" variant="text" color="primary" sx={{ height: '30px' }} onClick={clearFilters}>
                Clear filters
              </Button>
            </Box>
          </ContainerFluid>
          <ContainerFluid>
            <VideosGraph filteredVideos={filteredVideosExcludingDate || []} periodFilter={periodFilter} />
          </ContainerFluid>
          <ContainerFluid>
            {view === 'gallery' && <VideosGalleryView videos={filteredVideos} />}
            {view === 'table' && (
              <VideosTableView
                videos={filteredVideos}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={handleSelectionChange}
              />
            )}
          </ContainerFluid>
        </>
      )}
    </div>
  );
}

export default HomePage;
