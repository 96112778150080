import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BusinessIcon from '@mui/icons-material/Business';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box, Button, Card, CardActions, CardContent, Drawer, List, ListSubheader } from '@mui/material';
import NavLinkItem from 'components/nav/NavLinkItem';
import OrganizationSelector from 'components/organization';
import UploadVideoModal from 'components/video/Import/UploadVideoModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appSelectors } from 'redux/App';
import authSelectors from 'redux/Auth/auth.selectors';
import { useGetCollectionsQuery } from 'redux/services/collection';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useGetCurrentUserQuery } from 'redux/services/user';
import Avatar from 'ui-kit/avatar/Avatar';
import Header from 'ui-kit/header/Header';
import AskInput from './components/AskInput';

export default function MainMenu() {
  const navigate = useNavigate();
  const organizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(organizationUuid);
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const { data: currentUser } = useGetCurrentUserQuery();
  const handleUpgrade = () => {
    navigate(`/o/${organizationUuid}/settings/plan`);
  };
  const { data: collections } = useGetCollectionsQuery();

  if (!isAuthenticated) return null;

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          overflowX: 'hidden',
          boxSizing: 'border-box',
          width: '240px',
        },
        width: '240px',
      }}
      variant="permanent"
      anchor="left"
    >
      <Header>
        <OrganizationSelector />
      </Header>
      <AskInput />
      <List sx={{ paddingTop: '10px' }}>
        {currentUser && (
          <NavLinkItem
            to={'/user'}
            icon={<Avatar src={currentUser.pictureUrl} sx={{ width: '20px', height: '20px' }} />}
            title={'User Settings'}
          />
        )}
        <NavLinkItem to={'settings'} icon={<SettingsIcon />} title={'Organization Settings'} />
      </List>
      <List subheader={<ListSubheader component="div">Private</ListSubheader>}>
        <NavLinkItem to={`/o/${organizationUuid}/calendar`} icon={<TodayIcon />} title={'Calendar'} />
        <NavLinkItem to={`/o/${organizationUuid}/private`} icon={<PlayCircleIcon />} title={'Recordings'} />
      </List>
      <List
        subheader={
          <ListSubheader component="div">
            {currentOrganization ? `${currentOrganization.name}` : 'Organization'}
          </ListSubheader>
        }
      >
        <NavLinkItem to={`/o/${organizationUuid}/v`} icon={<BusinessIcon />} title={'Workspace'} end />
        <NavLinkItem to={`/o/${organizationUuid}/c`} icon={<VideoLibraryIcon />} title={'Collections'} end />
        {collections && (
          <Box sx={{ paddingLeft: '20px' }}>
            {collections.map((collection) => {
              return (
                <NavLinkItem
                  to={`/o/${organizationUuid}/c/${collection.uuid}`}
                  icon={<VideoLibraryIcon />}
                  title={collection.name}
                  end
                />
              );
            })}
          </Box>
        )}
      </List>
      <Box py={3} px={2}>
        <UploadVideoModal />
      </Box>
      <Box flexGrow={1} />
      <Box py={3} px={2}>
        {currentOrganization && currentOrganization.freePlan.free && (
          <Card>
            {currentOrganization.freePlan.reached && (
              <CardContent sx={{ textAlign: 'center' }}>No more recordings left on your Free plan.</CardContent>
            )}
            {!currentOrganization.freePlan.reached && (
              <CardContent sx={{ textAlign: 'center' }}>
                {currentOrganization.freePlan.limit - currentOrganization.freePlan.total} recordings remaining on your
                Free plan.
              </CardContent>
            )}
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoAwesomeIcon />}
                fullWidth
                onClick={handleUpgrade}
              >
                Upgrade
              </Button>
            </CardActions>
          </Card>
        )}
      </Box>
    </Drawer>
  );
}
