import VideoCard from '../VideoCard/VideoCard';
import CalendarCard from '../CalendarCard/CalendarCard';
import { Box } from '@mui/material';
import React from 'react';
import { Video } from '../../../redux/services/video.types';

const VideosGalleryView = ({ videos = [] }: { videos?: Video[] }) => {
  return (
    <Box display="flex" justifyContent="left" flexWrap="wrap" gap="10px">
      {videos &&
        videos.map((video) => {
          return <VideoCard key={video.uuid} video={video} />;
        })}
      {videos && videos.length === 0 && <CalendarCard />}
    </Box>
  );
};

export default VideosGalleryView;
