import { identify } from '@fullstory/browser';
import { call, select, takeEvery } from 'redux-saga/effects';

import { authActions, authSelectors } from 'redux/Auth';

const rootSaga = [takeEvery([authActions.loginSuccess, authActions.refreshTokenSuccess], onLogin)];

function* onLogin() {
  const uuid: string = yield select(authSelectors.getUserUuid);
  const displayName: string = yield select(authSelectors.getUsername);
  const email: string = yield select(authSelectors.getEmail);

  yield call(identify, uuid, { displayName, email });
}

export default rootSaga;
